/* eslint-disable import/prefer-default-export */
export const getCanonical = ({ location, siteUrl }: { location: string; siteUrl: string }): string => {
    let pathname = location || '/';

    if (pathname.endsWith('/')) {
        pathname = pathname.substring(0, pathname.length - 1);
    }

    return `${siteUrl}${pathname}`;
};

export const coerce = (bool: string | boolean | undefined): boolean => ![false, 'false', undefined].includes(bool);
