import type * as SentryBrowser from '@sentry/browser';
import canUseDom from '@clearscore/helpers.can-use-dom';
import type { Breadcrumb, Primitive } from '@sentry/types';
import { Severity } from '@sentry/types';

const GLOBAL_NAMESPACE = '__cs__';

export interface IMonitoring {
    identity(uuid?: string): void;
    tag(name?: string, value?: Primitive): void;
    property(name?: string, value?: unknown): void;
    addBreadcrumb(data?: Breadcrumb): void;
    error(err: unknown): void;
    log(msg?: string): void;
    withScope(func?: (scope: SentryBrowser.Scope) => void): void;
}

// TODO: check if this will tree shake properly
export default ({ Sentry, market }: { Sentry: typeof SentryBrowser; market: string }): IMonitoring => {
    if (!canUseDom()) return null;
    if (window[GLOBAL_NAMESPACE] && window[GLOBAL_NAMESPACE].monitoring) return null;

    /**
     * attach helpers to global namespace __cs__.monitoring
     */
    const monitoring: IMonitoring = {
        /**
         * set user properties
         * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#capturing-the-user
         */
        identity(uuid?: string) {
            if (uuid) {
                Sentry.configureScope((scope) => {
                    scope.setUser({ id: uuid });
                });
            }
        },

        /**
         * set tag against user
         * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#tagging-events
         */
        tag(name?: string, value?: Primitive) {
            if (name && value) {
                Sentry.configureScope((scope) => {
                    scope.setTag(name, value);
                });
            }
        },

        /**
         * set a property name to show against logging
         * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#extra-context
         */
        property(name?: string, value?: unknown) {
            if (name && value) {
                Sentry.configureScope((scope) => {
                    scope.setExtra(name, value);
                });
            }
        },

        /**
         * add use breadcrumb to logging
         * @summary https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browsernpm
         * @example
         * {
         *   category: 'auth',
         *   message: `Authenticated user`,
         * }
         */
        addBreadcrumb(data?: Breadcrumb) {
            if (data) {
                Sentry.addBreadcrumb({
                    level: Severity.Info,
                    ...data,
                });
            }
        },

        /**
         * log an error
         * @summary
         * https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-errors--exceptions
         */
        error(err: unknown) {
            if (err) {
                Sentry.captureException(err);
            }
        },

        /**
         * log a message
         * @summary https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-messages
         */
        log(msg?: string) {
            if (msg) {
                Sentry.captureMessage(msg);
            }
        },

        /**
         * log a message
         * @summary https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-messages
         */
        withScope(func?: Parameters<typeof Sentry.withScope>[0]) {
            if (func) {
                Sentry.withScope(func);
            }
        },
    };

    window[GLOBAL_NAMESPACE].monitoring = monitoring;

    monitoring.tag('market', market);
    return monitoring;
};
