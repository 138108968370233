import getEnv from '@clearscore/helpers.envs';
import type * as Sentry from '@sentry/browser';
import canUseDom from '@clearscore/helpers.can-use-dom';

import config from '../config/config';

const { release } = config;

const apps = ['app', 'beta', 'webviews', 'nova', 'adana', 'www', 'partner', 'ds', 'drivescore', 'auth'];
const productionRegExp = new RegExp(`https?://((cdn|cdn2|cdn2-fs|${apps.join('|')}).)?(clearscore|drivescore).com`);
const devRegExp = new RegExp(`https?://(${apps.join('|')})-(ci|stg).cs55.co.uk`);
const fluxRegExp = /https?:\/\/(admin|admin-ci|admin-stg)\.flux\.clearsccore\.io/;

export default ({ SENTRY_ID }: { SENTRY_ID?: string }): Sentry.BrowserOptions | null => {
    if (SENTRY_ID && canUseDom()) {
        const configuration: Sentry.BrowserOptions = {
            dsn: SENTRY_ID,
            /**
             * We can't replicate these issues and have no evidence it affects real
             * users, ignoring until we can prove otherwise to preserve quota
             * @see https://sentry.io/organizations/clearscore-technology-ltd/issues/2349280426/?project=1780025
             * @see https://sentry.io/organizations/clearscore-technology-ltd/issues/2345100663/?project=1780025
             */
            ignoreErrors: [/Loading chunk [0-9]+ failed/, /Non-Error promise rejection captured with value:(\s)*/],
            denyUrls: [
                /**
                 * Errors in this file are handled in `@clearscore/helpers.branch` but are still reported to
                 * sentry with `handled: yes` tag. We want to avoid that to preserve quota
                 */
                /(cdn|api2)\.branch\.io/i,
            ],
            whitelistUrls: [productionRegExp, devRegExp, fluxRegExp],
        };
        if (release?.release) configuration.release = release.release;
        if (release?.env) configuration.environment = release.env;
        if (getEnv('SENTRY_MAX_BREADCRUMBS')) configuration.maxBreadcrumbs = getEnv('SENTRY_MAX_BREADCRUMBS');
        if (getEnv('SENTRY_DEBUG')) configuration.debug = getEnv('SENTRY_DEBUG');
        if (getEnv('SENTRY_SAMPLE_RATE')) configuration.sampleRate = getEnv('SENTRY_SAMPLE_RATE');

        return configuration;
    }
    return null;
};
