import getDevice from '@clearscore/helpers.device';
import { MARKETS } from '@clearscore/config.i18n';
// eslint-disable-next-line import/no-internal-modules
import '@clearscore/ui.rainbow.tokens/tokens.css';
// eslint-disable-next-line import/no-internal-modules
import '@clearscore/ui.rainbow.drivescore.tokens/tokens.css';
import type { GatsbyBrowser } from 'gatsby';

import ProviderWrapper from './src/components/provider-wrapper';

const market = process.env.MARKET || MARKETS[0];
const device = getDevice();
const PAGE_VIEW_WEBSITE_NAME = 'well_good_homepage';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
    document.cookie = 'site_version=new;Path=/;Max-Age=2592000;';
    // it requires a polyfill for IE11 and android < 5
    if (typeof window.URLSearchParams === `undefined`) {
        import('url-search-params-polyfill');
    }
    if (typeof window.IntersectionObserver === 'undefined') {
        import('intersection-observer'); // needed for safari <=12.0
    }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
    if (window.analytics) {
        window.analytics.page({
            cs_site: market,
            cs_ed: new Date(),
            device,
            website: PAGE_VIEW_WEBSITE_NAME,
        });
    }
};

export const wrapRootElement = ProviderWrapper;
