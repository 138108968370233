import canUseDom from '@clearscore/helpers.can-use-dom';

import libraries from '.';

const thirdParties = [
    {
        name: 'segment',
        library: libraries.segment,
        loaded: false,
        ssr: false,
        required: 'SEGMENT_ID',
    },
    {
        name: 'appsFlyer',
        library: libraries.appsFlyer,
        loaded: false,
        ssr: false,
        required: 'APPS_FLYER_ID',
    },
    {
        name: 'sessionCam',
        library: libraries.sessionCam,
        loaded: false,
        ssr: false,
        required: 'SESSIONCAM_ACTIVE',
    },
    {
        name: 'sentry',
        library: libraries.sentry,
        loaded: false,
        ssr: false,
        required: 'SENTRY_ID',
    },
];

const loadThirdPartyScripts = (props, callback) => {
    thirdParties.forEach(({ library, loaded, ssr, required }, i) => {
        if (loaded) return;
        if (ssr || (canUseDom() && props?.[required])) {
            library(props, callback);
            thirdParties[i].loaded = true;
        }
    });
};

export default loadThirdPartyScripts;
