/* eslint-disable */
export default ({ APPS_FLYER_ID }) => {
    if (
        typeof APPS_FLYER_ID === "undefined" ||
        !/Android/i.test(navigator.userAgent)
    )
        return;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `!(function(e, n, t) { !(function(e, n, t, a, r) {
      var s = ((e[n] = e[n] || {})[t] = function() {
        (s._q = s._q || []).push(Array.prototype.slice.call(arguments));
      });
      s.webkey = r;
      for (var c = 0; c < a.length; c++)
        s[a[c]] = (function(n) {
          return function() {
            var e = Array.prototype.slice.call(arguments);
            return e.unshift(n), (s._q = s._q || []).push(e), s;
          };
        })(a[c]);
    })(e, 'AF', 'Banner', ['showBanner', 'hideBanner', 'disableBanners', 'disableTracking', 'setAdditionalParams'], t),
      (function(e, n, t) {
        var a = e.createElement('script');
        (a.type = 'text/javascript'), (a.async = !0), (a.src = n + (t ? '?webkey=' + t : ''));
        var r = e.getElementsByTagName('script')[0];
        r.parentNode.insertBefore(a, r);
      })(n, 'https://cdn.appsflyer.com/web-sdk/banner/latest/sdk.min.js', t);
  })(window, document, '${APPS_FLYER_ID}');
  window.AF.Banner.showBanner();`;
    document.head.appendChild(script);
};
